<template>
  <div>
    <order-modal
      v-if="savedOrder"
      :show="showOrderModal"
      :order="savedOrder"
      @close="closeModal"
    />
    <ca-header
      heading="Antrag erstellen"
      :column-layout="columnLayout"
      show-column-layout
      :loading="loading"
      @columnLayout="columnLayout = $event"
    >
      <template #buttonSpace>
        <submit-buttons
          class="col-auto"
          :order-creation-type="orderCreationType"
          :show-popover="!chassiIsAllowed"
          popover-placement="bottom"
          popover-parent-id="submit-top"
          @save="save"
        />
      </template>
      <template #alert>
        <ca-alert
          v-if="!!saveErrorMessage"
          variant="danger"
          :message="saveErrorMessage"
          @close="saveErrorMessage = null"
        />
        <ca-alert
          v-if="showFormInvalidHint"
          variant="info"
          message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
          @close="showFormInvalidHint = null"
        />
      </template>
      <template #customLoading>
        <ca-alert
          variant="info"
          spin-icon
          rounded
          icon="circle-notch"
          message="Lade Antragsdaten..."
        />
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div
          class="col-12"
          :class="{'col-xl-8 col-xxl-6': !columnLayout}"
        >
          <div class="row">
            <div
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <owner-form
                class="mb-3"
                :order="order"
              />
              <miscellaneous-form
                class="mb-3"
                :order="order"
              />
            </div>
            <div
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <BoatForm
                class="mb-3"
                :boat="order.boat"
                :chassi-is-allowed="chassiIsAllowed"
              />
              <product-form
                ref="productForm"
                class="mb-3"
                :order="order"
                :rates="rates"
                :pre-selected-product-hn="selectedProductHn"
                :mobility-rates="mobilityRates"
              />
            </div>
          </div>
          <div
            v-if="!loading"
            class="form-row justify-content-end"
          >
            <submit-buttons
              class="col-auto"
              :order-creation-type="orderCreationType"
              :show-popover="!chassiIsAllowed"
              popover-placement="top"
              popover-parent-id="submit-bottom"
              @save="save"
            />
          </div>
        </div>
        <dev-info :data="{order}" />
      </div>
      <div class="d-none">
        <button
          type="button"
          @click="saveToLocalstorage"
        >
          COPY
        </button>
        <button
          type="button"
          @click="applyFromLocalstorage"
        >
          PASTE
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header.vue'
import DevInfo from '@/components/DevInfo.vue'
import OwnerForm from '@/components/Forms/Order/OwnerForm.vue'
import BoatForm from '@/components/Forms/Order/BoatForm.vue'
import MiscellaneousForm from '@/components/Forms/Order/MiscellaneousForm.vue'
import ProductForm from '@/components/Forms/Order/ProductForm.vue'
import SubmitButtons from '@/components/Forms/Order/SubmitButtons.vue'
import OrderModal from '@/components/Modals/OrderModal.vue'
import debounce from 'lodash.debounce'
import feathers from '../../api'
import transformRateList from '../../helpers/transformRateList'
export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  name: 'OrderCreate',
  components: {
    DevInfo,
    OwnerForm,
    BoatForm,
    MiscellaneousForm,
    ProductForm,
    SubmitButtons,
    OrderModal,
    CaHeader
  },
  data () {
    return {
      loading: false,
      error: null,
      columnLayout: false,
      showOrderModal: false,
      order: null,
      savedOrder: null,
      mobilityRates: [],
      rates: [],
      ratesPending: false,
      savePending: false,
      saveError: null,
      saveErrorMessage: null,
      showFormInvalidHint: false,
      selectedProductHn: null,
      chassiIsAllowed: true
    }
  },
  computed: {
    orderCreationType () {
      if (this.rates.length === 0 || !this.chassiIsAllowed) {
        return 'request'
      } else {
        return 'contract'
      }
    },
    me () {
      return this.$store.getters['auth/user']
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    },
    'order.boat.km': {
      handler: 'updateRates'
    },
    'order.boat.monthFromRegistrationDate': {
      handler: 'updateRates'
    },
    'order.boat.chassi': {
      handler: 'fetchChassisAllow'
    },
    'order.mobility': {
      handler: 'fetchChassisAllow'
    },
    'order.product.rate': {
      handler: 'fetchChassisAllow'
    }
  },
  methods: {
    async save (orderType) {
      try {
        const valid = await this.$validator.validateAll()
        if (this.savePending) {
          return
        }
        if (!valid) {
          this.showFormInvalidHint = true
          return
        }
        this.savePending = true
        this.saveError = null
        this.saveErrorMessage = null
        this.showFormInvalidHint = false
        const isRequest = orderType === 'request'
        this.order.isContract = !isRequest
        this.order.status = isRequest ? 0 : 1
        if (this.order.product.rate) {
          this.order.product.rateId = this.order.product.rate._id
        }
        this.savedOrder = this.order
        this.showOrderModal = true
        if (!this.order.mobility || !this.order.mobilityRate || isRequest) {
          this.savedOrder = await this.order.save()
        } else {
          this.savedOrder = await feathers.service('order-mobility').create(this.order)
        }
      } catch (error) {
        console.log(error)
        let msg = ''
        switch (error.message) {
        case 'Authentication token missing.': msg = 'Sie sind nicht mehr eingeloggt. Loggen Sie sich neu ein'; break
        case 'Das Erstellen von Policen/Anfragen ist für diesen Account gesperrt': msg = error.message; break
        default: msg = 'Der Server konnte die Anfrage nicht bearbeiten'
        }
        this.saveErrorMessage = msg
        this.saveError = error
      } finally {
        this.savePending = false
      }
    },
    async fetchData () {
      try {
        this.loading = true
        this.order = new this.$FeathersVuex.api.Order()
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    debouncedFindRates: debounce(async function () {
      await this.findRates()
      this.ratesPending = false
    }, 500),
    updateRates () {
      this.rates = []
      if (this.$refs.productForm) this.$refs.productForm.reset()
      this.ratesPending = true
      this.debouncedFindRates()
    },
    async findRates () {
      const requiredValuesSet = [this.order.boat.kw, this.order.boat.monthFromRegistrationDate]
        .every(value => value !== null && value !== undefined && value !== '')
      if (requiredValuesSet) {
        const rateRequest = await this.$store.dispatch('rates/find', {
          query: {
            $limit: 500,
            $own: true,
            $or: [
              { deactivated: false },
              { deactivated: { $exists: false } }
            ],
            kwVon: {
              $lte: this.order.boat.kw
            },
            kwBis: {
              $gte: this.order.boat.kw
            },
            // kmVon: {
            //   $lte: this.order.boat.km
            // },
            // kmBis: {
            //   $gte: this.order.boat.km
            // },
            alterVon: {
              $lte: this.order.boat.monthFromRegistrationDate
            },
            alterBis: {
              $gte: this.order.boat.monthFromRegistrationDate
            }
          }
        })
        this.rates = transformRateList(rateRequest)
      }
    },
    async closeModal () {
      await this.$router.push({ name: 'OrderDetail', params: { orderId: this.savedOrder._id } })
      this.showOrderModal = false
    },
    saveToLocalstorage () {
      localStorage.setItem('orderData', JSON.stringify(this.order))
    },
    applyFromLocalstorage () {
      const orderData = JSON.parse(localStorage.getItem('orderData'))
      this.order.owner = orderData.owner
      this.order.boat = orderData.boat
    },
    async fetchChassisAllow () {
      if (this.order.boat.chassi.length <= 15 && this.order.product.rate) {
        const query = {
          chassiNumber: this.order.boat.chassi
        }
        try {
          this.chassiIsAllowed = await feathers.service('check-chassi-number').get(query)
        } catch (error) {
          this.error = error
          console.error(error)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.order-info{
  position: sticky;
  top: 70px;
}

</style>
