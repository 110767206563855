<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Boot
      </h4>
    </template>
    <template #body>
      <form
        novalidate
        @submit.prevent="save"
      >
        <div class="form-row my-3">
          <div class="col-12 col-md-6">
            <basic-typeahead
              id="licensePlate-country-char"
              v-model="boat.country.char"
              name="Land / Kennzeichen"
              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
              placeholder="Land / Kennzeichen"
              :horizontal="true"
              label="Land / Kennzeichen"
              :margin="false"
              :query="searchCountry"
              :input-formatter="country => country.char.toString()"
              :result-formatter="countryResultFormatter"
              @hit="setCountry"
            />
          </div>
          <div class="col-12 col-md-6">
            <basic-input
              id="licensePlate"
              v-model="boat.licensePlate"
              :margin="false"
              placeholder="Kennzeichen"
            />
          </div>
        </div>

        <basic-input
          :value="boat.producer"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Hersteller"
          :horizontal="true"
          label="Hersteller"
          required
          @input="$set(boat, 'producer', $event.toUpperCase())"
        />

        <basic-input
          :value="boat.type"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Typ"
          :horizontal="true"
          label="Typ"
          required
          @input="$set(boat, 'type', $event.toUpperCase())"
        />

        <basic-input
          :value="boat.chassi"
          data-test="car-form-chassi"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="XXXXXXXXXXXXXXX"
          :horizontal="true"
          :validation="{
            max: 15
          }"
          maxlength="15"
          label="Rumpfnummer"
          required
          input-prevent-default
          @input="sanitizeChassi"
        >
          <!-- <template #small>
            <small
              v-if="chassiInputManipulated"
              class="text-danger"
            ><br>Die FIN darf nur aus arabischen  Ziffern und lateinischen Buchstaben, außer I, O und Q, bestehen</small>
          </template> -->
        </basic-input>
        <div class="form-row my-3">
          <div class="col-12 col-md-8">
            <basic-datepicker
              v-model="boat.registrationDate"
              data-test="boat-form-registration-date"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              :horizontal="true"
              required
              label="Erstzulassung"
              :disabled="extend"
              placeholder="TT.MM.JJJJ"
              :margin="false"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-input
              v-model="boat.monthFromRegistrationDate"
              :margin="false"
              placeholder="Alter in Monaten"
              disabled
            >
              <template #append>
                <span class="input-group-text">Monat(e)</span>
              </template>
            </basic-input>
          </div>
        </div>
        <div class="form-row my-3">
          <div class="col-12 col-md-8">
            <basic-input
              v-model="boat.ccm"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              :horizontal="true"
              required
              label="Hubraum/kW"
              placeholder="ccm"
              :margin="false"
              type="number"
            >
              <template #append>
                <span class="input-group-text">ccm</span>
              </template>
            </basic-input>
          </div>
          <div class="col-12 col-md-4">
            <basic-input
              v-model="boat.kw"
              :margin="false"
              required
              placeholder="kW"
              type="number"
            >
              <template #append>
                <span class="input-group-text">kW</span>
              </template>
            </basic-input>
          </div>
        </div>

        <basic-datepicker
          v-model="boat.milageMeasureDate"
          data-test="boat-form-km-measure-date"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="km abgelesen am"
          placeholder="TT.MM.JJJJ"
          required
        />

        <basic-input
          v-model="boat.runtime"
          data-test="boat-form-runtime"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          placeholder="Betriebsstunden"
          label="Betriebsstunden"
          :validation="{ regex: /^[0-9]+$/ }"
          required
        >
          <template #append>
            <span class="input-group-text">h</span>
          </template>
        </basic-input>

        <BasicCurrencyInput
          v-if="!isKaufschutz"
          v-model="boat.price"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          placeholder="Kaufpreis"
          label="Kaufpreis"
        >
          <template #append>
            <span class="input-group-text">€</span>
          </template>
        </BasicCurrencyInput>

        <basic-select
          v-model="boat.fuel"
          data-test="car-form-fuel"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Kraftstoff"
          :horizontal="true"
          label="Kraftstoff"
          required
          :options="[
            {label: 'Benzin', value: 'Benzin'},
            {label: 'Diesel', value: 'Diesel'},
            {label: 'Elektro', value: 'Elektro'},
            {label: 'Hybrid', value: 'Hybrid'},
            {label: 'Erdgas', value: 'Erdgas'},
            {label: 'LPG', value: 'LPG'},
          ]"
        />
        <basic-select
          v-model="boat.usage"
          data-test="car-form-usage"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Nutzungsart"
          :horizontal="true"
          label="Nutzungsart"
          required
          :options="[
            {label: 'ausschließlich gewerblich', value: 'ausschließlich gewerblich'},
            {label: 'ausschließlich privat', value: 'ausschließlich privat'},
            {label: 'überwiegend gewerblich', value: 'überwiegend gewerblich'},
            {label: 'überwiegend privat', value: 'überwiegend privat'},
          ]"
        />
      </form>
    </template>
  </ca-card>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker'
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead'
import BasicCurrencyInput from '@/components/BaseComponents/BasicCurrencyInput'
import CaCard from '@/components/Card'
import moment from 'moment'
import SanitizeChassi from '../../../helpers/sanitizeChassi'

export default {
  inject: ['$validator'],
  components: {
    BasicInput,
    BasicSelect,
    BasicDatepicker,
    BasicTypeahead,
    BasicCurrencyInput,
    CaCard
  },
  props: {
    boat: {
      type: Object,
      required: true
    },
    extend: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    isKaufschutz: {
      type: Boolean,
      default: false
    },
    hsnTsnDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    chassiInputManipulated: false
  }),
  watch: {
    'boat.registrationDate': {
      immediate: true,
      handler: 'setMonthFromRegistrationDate'
    }
  },
  methods: {
    setMonthFromRegistrationDate (registrationDate) {
      if (registrationDate) {
        let diff = moment().diff(registrationDate, 'months', false)
        if (diff != null) {
          if (diff < 0) diff = 0
          const monthFromRegistrationDate = diff + 1
          this.$set(this.boat, 'monthFromRegistrationDate', monthFromRegistrationDate)
        };
      }
    },
    searchCountry (char) {
      return this.$store.dispatch('countries/find', {
        query: {
          $limit: 10, char: char.toUpperCase()
        }
      })
    },
    countryResultFormatter (country) {
      return `${country.char} ${country.name}`
    },
    setCountry (country) {
      this.boat.country = {
        ...country
      }
    },
    sanitizeChassi (chassi) {
      const sanitized = SanitizeChassi(chassi)
      this.chassiInputManipulated = sanitized.manipulated
      this.$set(this.boat, 'chassi', sanitized.chassi)
    }
  }
}
</script>
